import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ResizableTextarea, fonts, space } from 'folio-common-components';
import * as React from 'react';
import { WarningBox } from '../../components/warning-box';
import type {
  ActivityId,
  Purposetext,
} from '../../routes/step1/CompanyPurposeForm';
import { checkCompanyPurpose } from '../../services';
import { useSelector } from '../../state';
import { isProbablyMobile } from '../../utils';
import { Button, TextButton } from '../buttons';

const WideTextarea = styled(ResizableTextarea)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  ${fonts.font100book};
  ${space([32], 'margin-top')};
`;

const Heading = styled.h4`
  ${fonts.font300medium};
  margin: 0;
  ${space([4], 'margin-bottom')};
`;

const emojiRegex =
  /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDEFF])/;

const textContainsEmoji = (text: string) => emojiRegex.test(text);

type Props = {
  onResetClicked: () => void;
  onPurposeChosen: (args: ActivityId | Purposetext) => void;
};
export const CustomCompanyPurposeForm: React.FC<Props> = props => {
  const { onResetClicked, onPurposeChosen } = props;
  const purpose = useSelector(e => e.editor.coreInfo.purpose);
  const [value, setValue] = React.useState(purpose || '');
  const [textFeedback, setTextfeedback] = React.useState('0/100');
  const [textError, setTextError] = React.useState(false);
  const [storeError, setStoreError] = React.useState(false);
  const [isFirstEdit, setIsFirstEdit] = React.useState(value.length === 0);
  const [_loading, setLoading] = React.useState(false);
  const describeId = React.useId();
  const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);

  React.useLayoutEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.focus({ preventScroll: true });
      textArea.setSelectionRange(textArea.value.length, textArea.value.length);
    }
  }, [textAreaRef]);

  React.useLayoutEffect(() => {
    const descriptionLength = value.trim().length;

    setTextError(false);
    setStoreError(false);
    setTextfeedback(`${descriptionLength}/100`);

    if (!isFirstEdit || descriptionLength > 0) {
      setIsFirstEdit(false);

      if (textContainsEmoji(value)) {
        setTextfeedback('Emojis vil bli fjernet fra teksten');
      }

      if (descriptionLength < 4) {
        setTextfeedback(`${descriptionLength}/100 – teksten er for kort!`);
        setTextError(true);
      }

      if (descriptionLength > 100) {
        setTextfeedback(`${descriptionLength}/100 – teksten er for lang!`);
        setTextError(true);
      }
    }
  }, [value, isFirstEdit]);

  async function handleSavePurpose(text) {
    // early return if known errors
    if (textError) {
      return setStoreError(true);
    }

    // remove emojis
    text = text.replace(emojiRegex, '');

    // check brreg purpose
    setLoading(true);
    const purposeIssues = await checkCompanyPurpose(text);
    setLoading(false);

    if (purposeIssues) {
      setStoreError(true);
      return setTextfeedback(
        'Beskrivelsen er ikke god nok. Les instruksjonen og prøv igjen.',
      );
    } else {
      return onPurposeChosen({
        purpose: text,
        kind: 'purposeText',
      });
    }
  }

  return (
    <div
      css={css`
        border-radius: var(--border-radius);
        border: 2px solid var(--tt-blue-100);
        ${space([16], 'padding')};
      `}
    >
      <Heading>Skriv selv</Heading>
      <div id={describeId}>
        <p>Beskrivelsen din må godkjennes av Brønnøysundregistrene.</p>
        <WarningBox
          css={css`
            ${space([16], 'margin-bottom')};
          `}
        >
          <b>Dette må til for å få teksten godkjent</b>
          <ul>
            <li>
              Beskriv hva slags produkter du skal lage eller selge, og til hvem.
            </li>
            <li>Opplys gjerne om hvilken bransje virksomheten er innenfor.</li>
            <li>Skriv på norsk.</li>
          </ul>
          <p>
            Eksempel: Nettbutikk med produkter innen trening, skjønnhet og
            velvære, med hovedvekt på salg av trening- og fritidsklær.
          </p>
        </WarningBox>
      </div>
      <WideTextarea
        label="Din beskrivelse"
        message={{
          kind: storeError ? 'error' : 'message',
          content: textFeedback,
        }}
        value={value}
        ref={textAreaRef}
        onChange={v => setValue(v)}
        id="custom-purpose"
        data-testid="custom-purpose"
        aria-label="Hva er selskapets formål?"
        aria-describedby={describeId}
        rows={5}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={!isProbablyMobile}
      />
      <ButtonContainer>
        <Button
          disabled={value.length === 0}
          onClick={() => handleSavePurpose(value)}
        >
          Lagre
        </Button>
        <TextButton
          css={css`
            ${space([16], 'margin-left')};
          `}
          onClick={() => {
            onResetClicked();
          }}
        >
          Avbryt
        </TextButton>
      </ButtonContainer>
    </div>
  );
};
