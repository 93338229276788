import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { brregTexts } from 'folio-brreg-standardized-texts';
import {
  Autosuggest,
  type AutosuggestInputProps,
  PlainButton,
  fonts,
  shadows,
  space,
} from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { MagnifyingGlassIcon } from '../../icons';
import type {
  ActivityId,
  Purposetext,
} from '../../routes/step1/CompanyPurposeForm';
import { type SuggestionText, createSuggestions } from './searchUtil';

const MAX_SUGGESTION_COUNT = 5;

const SuggestionContainer = styled.div`
  ${space([8], 'padding')};
`;
const SuggestionsHeading = styled.div`
  color: ${colors.wcagGray};
  ${fonts.font100book};
`;
const StandardizedText = styled.div`
  ${fonts.font200book};
`;

function itemMarkup(item: SuggestionText) {
  return (
    <SuggestionContainer>
      <SuggestionsHeading>
        {formatters.capitalize(item.heading)}
      </SuggestionsHeading>
      <StandardizedText>{item.text}</StandardizedText>
    </SuggestionContainer>
  );
}

type Props = {
  searchString: string;
  setSearchString: (s: string) => void;
  onCustomClicked: () => void;
  onPurposeChosen: (args: ActivityId | Purposetext) => void;
  inputId: string;
  shouldFocusInput: boolean;
};

export const CompanyPurposeSearch: React.FC<Props> = props => {
  const {
    searchString,
    setSearchString,
    onCustomClicked,
    shouldFocusInput,
    inputId,
    onPurposeChosen,
  } = props;
  const [suggestions, setSuggestions] = React.useState<SuggestionText[]>([]);

  const inputProps: AutosuggestInputProps<any> = {
    id: inputId,
    value: searchString,
    autoFocus: shouldFocusInput,
    spellCheck: false,
    autoComplete: 'off',
    placeholder: 'Søk etter for eksempel klesbutikk eller psykolog',
    onChange: (_, params) => {
      setSearchString(params.newValue);
    },
    css: css`
      width: 100%;
      border-radius: ${searchString.length > 0 ? '8px 8px 0 0' : null};
    `,
  };

  // ${searchString.length > 0 ? 'border-radius: 8px 8px 0 0' : null};
  return (
    <Autosuggest<SuggestionText>
      icon={<MagnifyingGlassIcon />}
      suggestions={suggestions}
      onSuggestionsClearRequested={() => undefined}
      onSuggestionsFetchRequested={({ value }) => {
        const sanitizedValue = value.trim().toLowerCase();
        const suggestions = createSuggestions(sanitizedValue, brregTexts);

        setSuggestions(suggestions.slice(0, MAX_SUGGESTION_COUNT));
      }}
      onSuggestionSelected={(event, { suggestion, method }) => {
        if (method === 'enter') {
          event.preventDefault();
        }

        onPurposeChosen({
          activityId: suggestion.id,
          kind: 'activityId',
        });
      }}
      getSuggestionValue={() => searchString}
      shouldRenderSuggestions={value => value.trim().length > 0}
      renderSuggestion={itemMarkup}
      inputProps={inputProps}
      alwaysRenderSuggestions={searchString.trim().length > 0}
      renderSuggestionsContainer={({ containerProps, children, query }) => {
        if (!query) {
          return null;
        }

        const { key, ...rest } = containerProps;

        return (
          <div
            key={key}
            {...rest}
            css={css`
              display: grid;
              grid-template-rows: ${suggestions.length}fr ${MAX_SUGGESTION_COUNT -
                suggestions.length}fr;
              position: static !important;
              z-index: 1;
              width: 100%;
              background: #fff;
              border: 2px solid var(--tt-blue-100) !important;
              border-radius: 0 0 8px 8px;
              margin-top: -2px;
              overflow: auto;
              -webkit-overflow-scrolling: touch;
              max-height: none;
              height: 360px !important;

              & ul {
                display: grid;
                box-shadow: ${shadows.smallShadow};
              }
            `}
          >
            {children ?? <div />}
            {suggestions.length < MAX_SUGGESTION_COUNT ? (
              <div
                css={css`
                  background-color: ${colors.grayLight};
                  width: 100%;
                  display: grid;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <div
                  css={css`
                    ${fonts.font200book};
                    text-align: center;
                    color: ${colors.wcagGray};
                    max-width: 270px;
                  `}
                >
                  Hvis du ikke finner noe som passer må du{' '}
                  <PlainButton
                    onClick={onCustomClicked}
                    css={css`
                      color: var(--tt-blue-100);
                    `}
                  >
                    skrive egen beskrivelse
                  </PlainButton>
                </div>
              </div>
            ) : null}
          </div>
        );
      }}
    />
  );
};
