export const mountPath = '';

function toFullPath(path: string) {
  return path === '' ? mountPath : `${mountPath}/${path}`;
}

// This needs to be kept in sync with index.ts in stiftemaskinen-bff
export const pageMap = {
  home: toFullPath(''),
  step1: toFullPath('om-selskapet'),
  step2: toFullPath('om-deg'),
  step3: toFullPath('eierskap'),
  step4: toFullPath('drift'),
  step5: toFullPath('innspurt'),
  signing: toFullPath('signering'),
  status: toFullPath('status'),
  about: toFullPath('om-stiftemaskinen'),
  foundingFaq: toFullPath('stifte-as'),
  findOutMore: toFullPath('finn-ut-mer'),
  citizenship: toFullPath('statsborgerskap'),
  preControlFeedback: toFullPath('feil'),
  resetBankSelection: toFullPath('velg-bank'),
};

export const pageTitleMap: Record<Page, string> = {
  home: '',
  step1: 'Om selskapet',
  step2: 'Om deg',
  step3: 'Eierskap',
  step4: 'Drift',
  step5: 'Innspurt',
  status: 'Status',
  signing: 'Signering',
  about: 'Om Start AS med Tripletex',
  foundingFaq: 'Om å stifte AS',
  findOutMore: 'Finn ut mer',
  citizenship: 'Statsborgerskap og bedriftskonto',
  preControlFeedback: 'Noe gikk galt',
  resetBankSelection: 'Velg Folio',
};

export type Page = keyof typeof pageMap;

export const pathMap: Record<string, Page> = {};

for (const page of Object.keys(pageMap)) {
  const path = pageMap[page];
  pathMap[path] = page as Page;
}
