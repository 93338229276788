import styled from '@emotion/styled';
import { fonts, space } from 'folio-common-components';
import * as React from 'react';
import type {
  ActivityId,
  Purposetext,
} from '../../routes/step1/CompanyPurposeForm';
import { isProbablyMobile, removeTrailingDot } from '../../utils';
import { track } from '../../utils/track';
import { Button, TextButton } from '../buttons';

const Container = styled.div`
  ${space([32], 'margin-top')};
`;
const Box = styled.div`
  border-radius: var(--border-radius);
  border: 2px solid var(--tt-blue-100);
  ${space([16], 'padding', 'margin-bottom', 'gap')};
`;
const Heading = styled.h4`
  ${fonts.font200book};
  ${space([16], 'margin-bottom')};
`;
const SubHeading = styled.h5`
  ${fonts.font200book};
  ${space([0], 'margin')};
`;
const StandardizedText = styled.p`
  ${fonts.font400book};
  ${space([8], 'margin-top')};
  ${space([16], 'margin-bottom')};
`;

export type ShortcutInfo = {
  shortcutLabel: string;
  standardizedLabel: string;
  standardizedId: string;
};

type Props = {
  shortcutInfo: ShortcutInfo;
  onResetClicked: () => void;
  onPurposeChosen: (args: ActivityId | Purposetext) => void;
};

export const ShortcutClickedForm: React.FC<Props> = props => {
  const { shortcutInfo, onResetClicked, onPurposeChosen } = props;
  const { standardizedId, standardizedLabel, shortcutLabel } = shortcutInfo;

  const describeId = React.useId();

  return (
    <Container>
      <Heading>
        Forslag for <strong>{shortcutLabel}</strong>
      </Heading>
      <Box>
        <div id={describeId}>
          <SubHeading>Samsvarer med offisiell beskrivelse:</SubHeading>
          <StandardizedText>
            «{removeTrailingDot(standardizedLabel)}»
          </StandardizedText>
        </div>
        <Button
          onClick={() => {
            track('aktivitetsvelger', 'snarvei valgt', shortcutLabel, 0, false);
            onPurposeChosen({
              activityId: standardizedId,
              kind: 'activityId',
            });
          }}
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus={!isProbablyMobile}
          aria-describedby={describeId}
        >
          Bruk denne
        </Button>
        <TextButton
          onClick={() => {
            track(
              'aktivitetsvelger',
              'snarvei tilbake',
              shortcutLabel,
              0,
              false,
            );
            onResetClicked();
          }}
          css={space([16], 'margin-left')}
        >
          Tilbake
        </TextButton>
      </Box>
    </Container>
  );
};
