import { css } from '@emotion/react';
import { Spinner, TextInput } from 'folio-common-components';
import * as React from 'react';
import { useDebounce } from 'use-debounce';
import { BuildingIcon, SmallArrowRightIcon } from '../../icons';
import { type RootState, useActions, useSelector } from '../../state';
import { selectors as companySelectors } from '../../state/companyCheck';
import { getCompanyNameLength, normalizeCompanyName } from '../../utils';
import { msgsToComponents } from '../../utils/msgs-to-components';
import {
  companyNameIsSyntacticallyValid,
  getIllegalCompanyNameCharacters,
  startsWithCompanyTypeAbbreviation,
} from '../../utils/validators';

const msgs = {
  NameRules: (
    <>
      Navnet må bestå av minst tre bokstaver og slutte med «AS».{' '}
      <a
        href="https://altinn.no/starte-og-drive/starte/valg-av-navn/"
        target="_blank"
      >
        Flere&nbsp;regler
        <SmallArrowRightIcon
          css={css`
            position: relative;
            top: 2px;
            margin-left: 4px;
          `}
        />
      </a>
    </>
  ),
};

const Msg = msgsToComponents(msgs);

function selector(state: RootState) {
  return {
    info: state.editor.coreInfo,
    companyNameStatus: companySelectors.checkCompany(
      state,
      normalizeCompanyName(state.editor.coreInfo.name),
    ),
  };
}

export const CompanyInfoForm: React.FC = () => {
  const { companyNameStatus, info } = useSelector(selector);
  const { companyCheckThunks, editorActions } = useActions();
  const [companyName] = useDebounce(normalizeCompanyName(info.name), 1500);
  const [illegalCharacters, setIllegalCharacters] = React.useState<string[]>();
  const [tooShort, setTooShort] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [illegalAbbreviation, setIllegalAbbreviation] = React.useState(false);
  const onChange = editorActions.setCoreInfo;

  React.useEffect(() => {
    const validate = async name => {
      setLoading(true);
      await companyCheckThunks.validateCompanyName(name);
      setLoading(false);
    };

    if (getCompanyNameLength(companyName) > 0) {
      setTooShort(getCompanyNameLength(companyName) < 3);
    }

    setIllegalCharacters(getIllegalCompanyNameCharacters(companyName));

    setIllegalAbbreviation(startsWithCompanyTypeAbbreviation(companyName));

    if (companyNameIsSyntacticallyValid(companyName)) {
      validate(companyName);
    }
  }, [companyName, companyCheckThunks]);

  const errorMessage =
    companyNameStatus !== 'in_progress' && !companyNameStatus.existingNameOk
      ? 'Navnet er allerede i bruk'
      : illegalCharacters && illegalCharacters.length > 0
      ? 'Du har brukt ugyldige tegn'
      : companyNameStatus !== 'in_progress' && !companyNameStatus.properNameOk
      ? 'Du kan ikke bruke navnet til et land, fylke eller en kommune'
      : tooShort
      ? 'Selskapsnavnet er for kort'
      : illegalAbbreviation
      ? 'Navn som starter med forkortelsen av en selskapsform godtas sjelden av Brønnøysundregistrene'
      : '';

  return (
    <form
      onSubmit={evt => {
        evt.preventDefault();
      }}
    >
      <p>
        <Msg.NameRules />
      </p>
      <TextInput
        label="Navn"
        onChange={name => onChange({ name })}
        onBlur={() => {
          // If the company name would be valid if "AS" was appended,
          // we automatically insert "AS" at the end.
          if (
            companyNameIsSyntacticallyValid(normalizeCompanyName(info.name))
          ) {
            onChange({ name: normalizeCompanyName(info.name) });
          }
        }}
        id="companyName"
        value={info.name}
        icon={<BuildingIcon />}
        autoComplete="off"
        autoCorrect="off"
        message={
          errorMessage
            ? {
                kind: illegalAbbreviation ? 'message' : 'error',
                content: errorMessage,
              }
            : undefined
        }
        rightContent={loading ? { content: <Spinner />, width: 24 } : undefined}
      />
    </form>
  );
};
